import { gql } from "apollo-boost";

export const authMixin = {
    methods: {
    async adminLogin(adminInput) {
        const adminLogin = gql`
        mutation adminLogin($adminInput: AdminInput!){
            adminLogin(adminInput: $adminInput){
                message{
                    message
                    code
                }
                user{
                    firstName
                    lastName
                    email
                    userType
                }
                tokens{
                    accessToken
                    accessTokenExpiresIn
                    refreshToken
                    refreshTokenExpiresIn
                }
            }
          }
        `;
        return this.$apollo.mutate({
          mutation: adminLogin,
          variables: {
            adminInput
          }
        })
        .then(response =>{
          console.log("response", response)
          let apiResponse = response.data.adminLogin
          localStorage.setItem(
            "user",
            JSON.stringify(apiResponse.user)
          );

          // Set accessToken and refresh token
          localStorage.setItem(
            "accessToken",
            apiResponse.tokens.accessToken
          );
          localStorage.setItem(
            "refreshToken",
            apiResponse.tokens.refreshToken
          );
          // var date = new Date().getTime() + 60 * 60 * 24 * 1000;
          localStorage.setItem("tokenExpiry", apiResponse.tokens.accessTokenExpiresIn);
          localStorage.setItem("loggedIn", "true");
          this.$store.commit('UPDATE_USER_INFO', apiResponse.user)
            return apiResponse
        })
        .catch(error =>{
          return {code : 400}
        });
      },
      adminLogout(){
        localStorage.clear();
      },
      async adminForgotPassword(email) {
          const adminForgotPassword = gql`
          mutation adminForgotPassword($email: String!){
            adminForgotPassword(email: $email){
                  message{
                      message
                      code
                  }
              }
            }
          `;
          // const { result, loading, error } = await this.$apollo.mutate({
          //   mutation: adminForgotPassword,
          //   variables: {
          //     email
          //   },
          // })
          // console.log("e", error)
          // return false
          return this.$apollo.mutate({
            mutation: adminForgotPassword,
            variables: {
              email
            },
          })
          .then(response =>{
              return response.data.adminForgotPassword
          })
          .catch(error =>{
              return {code : 400}
          });
        },
        async changeAdminPassword(oldPassword, newPassword) {
            const adminChangePassword = gql`
            mutation adminChangePassword($oldPassword: String! $newPassword: String!){
              adminChangePassword(oldPassword: $oldPassword newPassword: $newPassword){
                    message{
                        message
                        code
                    }
                }
              }
            `;
            return this.$apollo.mutate({
              mutation: adminChangePassword,
              variables: {
                oldPassword,
                newPassword
              },
            })
            .then(response =>{
                return response.data.adminChangePassword
            })
            .catch(error =>{
                return {code : 400}
            });
          },
    },
  }
