<template>

  <div class="h-screen flex w-full bg-img">
    <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
             <!--  <img src="@/assets/images/logo/redwithEar.png" width="250px" alt="register" class="mx-auto"> -->
              <!-- <img src="" width="150px" alt="register" class="mx-auto"> -->
              <img src="@/assets/images/kulcha_logo.png" width="330px" alt="register" class="mx-auto">

            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Recover your password</h4>
                  <p>Please enter your email address and we'll send you instructions on how to reset your password.</p>
                </div>
                <vs-alert
                  :active.sync="showDismissibleAlert"
                  closable
                  icon-pack="feather"
                  close-icon="icon-x"
                >{{message}}</vs-alert>
                <br />
                <vs-input
                  v-validate="'required|email|min:3'"
                  data-vv-validate-on="blur"
                  name="email"
                  icon-no-border
                  icon="icon icon-user"
                  icon-pack="feather"
                  label-placeholder="Email"
                  v-model="email"
                  class="w-full mb-8"/>
                <span class="text-danger text-sm">{{ errors.first('email') }}</span>
               <br>
                <vs-button type="border" to="/admin/login" class="px-4 w-full md:w-auto">Back To Login</vs-button>
                <vs-button @click="recoverPassword" class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0">Recover Password</vs-button>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { authMixin } from '../../mixins/authMixin'
  export default {
    mixins: [authMixin],
    data() {
      return {
          email: '',
        showDismissibleAlert: false,
        message: '',
        loginError: ''
      }
    },
    computed: {
      validateForm() {
        return !this.errors.any() && this.email != '';
      },
    },
    methods: {
      ...mapActions('users', ['forgotPassword']),
      recoverPassword() {
        this.$validator.validateAll().then(async(result)  => {
          if(result) {

            this.$vs.loading();
            const response = await this.adminForgotPassword(this.email)
            this.$vs.loading.close();

            if(response.message && response.message.code == 200){
                this.showDismissibleAlert = true
                this.message = response.message.message
            }
            else{
                this.showDismissibleAlert = true
                this.message = "User associated with this email not found."
                this.$vs.loading.close()
                this.$vs.notify({
                  title: 'Error',
                  text: "User associated with this email not found.",
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })
            }
          }
        })
      }
    }
  }
</script>
